<template>
  <div
    v-if="that"
    class="wrap-request-items"
  >
    <b-card
      class="ecommerce-card mb-0"
      no-body
    >
      <b-card-body>
        <b-badge
          pill
          variant="light-primary"
          class="mb-25"
        >
          {{ props.booking_id }}
        </b-badge>
        <p class="mb-0 font-10">
          {{ $t('REQUEST') }}
        </p>
        <div class="mb-50 font-weight-bold font-14">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="p-0">
                  #
                </th>
                <th class="p-0">
                  Service
                </th>
                <th class="p-0">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) of that.getCategoryArray(props)"
                :key="key"
              >
                <td class="p-0">
                  {{ key + 1 }}.
                </td>
                <td class="p-0">
                  {{ item.service }}
                </td>
                <td class="p-0">
                  {{ item.currency_symbol }} {{ item.total_service_price }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="mb-0">
          {{ $t('Status') }}: {{ props.status }}
        </p>
        <p class="mb-0">
          {{ $t('Agent') }}: {{ props.agent ? props.agent.name : 'N/A' }}
        </p>
        <div class="row text-center mt-1 justify-content-around">
          <div class="col-md-7 request-date-price">
            <p class="mb-25">
              {{ $t('Scheduled Date') }}
            </p>
            <p class="mb-0">
              {{ props.timeslot.booking_date }}
            </p>
          </div>
          <div class="col-md-4 request-date-price">
            <p class="mb-25">
              {{ $t('Amount') }}
            </p>
            <p class="mb-0">
              {{ that.getServicePrice(props) }}
            </p>
          </div>
        </div>
        <div class="d-flex text-center mt-1 justify-content-around">
          <b-button
            variant="primary"
            class="mr-1"
            @click="that.viewServiceHistory(props)"
          >
            {{ $t('View') }}
          </b-button>
          <b-button
            variant="success"
            class="mr-1"
            @click="that.updateRequestApproval(props, 'ACCEPTED')"
          >
            {{ $t('Accept') }}
          </b-button>
          <b-button
            variant="danger"
            @click="that.updateRequestApproval(props, 'CANCELLED')"
          >
            {{ $t('Reject') }}
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BBadge, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    that: {
      typeof: Object,
      default: () => {},
    },
    // eslint-disable-next-line vue/require-prop-types
    props: {
      typeof: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>
.request-date-price {
  background: #f8f8f8;
  padding: 8px 5px;
}

.wrap-request-items {
  width: 25%;
  display: inline-block;
  margin-right: 0;
}
</style>

<template>
  <b-card class="provider-find-agents">
    <div
      v-if="orderDetails"
      class="row mx-auto justify-content-center mb-1"
    >
      <div class="col-md-3 bg-primary rounded-left">
        <b-media class="pb-50 pt-50 align-items-center">
          <template #aside>
            <b-avatar :src="require('@/assets/images/app-icons/accept.png')" />
          </template>
          <h6 class="text-white">
            {{ orderDetails.booking_id }}
          </h6>
        </b-media>
      </div>
      <div
        v-if="orderDetails.user"
        class="col-md-3 bg-primary"
        :class="{ 'rounded-right': !assignedAgent }"
      >
        <b-media class="pb-50 pt-50 align-items-center">
          <template #aside>
            <b-avatar :src="require('@/assets/images/app-icons/map.png')" />
          </template>
          <h6 class="text-white">
            {{ orderDetails.user.first_name }} {{ orderDetails.user.last_name }}
          </h6>
          <p
            v-if="orderDetails.delivery"
            class="mb-0 text-white"
          >
            {{ orderDetails.delivery.map_address }}
          </p>
        </b-media>
      </div>
      <div
        v-if="assignedAgent"
        class="col-md-3 bg-primary rounded-right"
      >
        <b-media class="pb-50 pt-50 align-items-center">
          <template #aside>
            <b-avatar :src="require('@/assets/images/app-icons/express-delivery.png')" />
          </template>
          <h6 class="text-white">
            {{ assignedAgent.first_name }} {{ assignedAgent.last_name }}
          </h6>
          <p class="mb-0 text-white">
            {{ assignedAgent.mobile }}
          </p>
        </b-media>
      </div>
    </div>
    <b-row>
      <b-col
        v-if="orderId"
        cols="12"
      >
        <good-data-table
          ref="findAgents"
          :mode="'local'"
          :button-label="''"
          :columns="storeTypeColumns"
          :total-column="'response.responseData.length'"
          :data-column="'response.responseData'"
          :api-endpoint="getApiEndpoint()"
          @table-response="changeParams"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BMedia, BAvatar } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import eventBus from '@/libs/event-bus'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from './useProvider'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    BCard,
    BMedia,
    BAvatar,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    orderId: {
      typeof: String,
      default: '',
    },
  },
  data() {
    return {
      assignedAgent: null,
      editStoreType: null,
      activeCity: {},
      showStoreTypesModal: false,
      orderDetails: null,
      allAgents: [],
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
    storeTypeColumns() {
      return [
        {
          label: 'Profile',
          field: 'picture',
          preventExport: true,
          useHtml: true,
          excelHtml: props => window.SuperApp.helper.image(props.picture),
          renderer: props => (props.picture ? `<img src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" style="height: 35px;" />` : 'NA'),
        },
        {
          label: 'Agent Name',
          field: 'first_name',
          rField: 'first_name_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => `${props.first_name} ${props.last_name}`,
        },
        {
          label: 'Pickup ETA and Distance',
          field: 'pickup_duration_text',
          useHtml: true,
          useResolver: true,
          textRenderer: props => `In ${props.pickup_duration} Mins ${props.pickup_distance} km`,
          renderer: props => `<b>In ${props.pickup_duration} Mins</b> <br />${props.pickup_distance} km`,
        },
        {
          label: 'Delivery ETA and Distance',
          field: 'delivery_duration_text',
          useHtml: true,
          useResolver: true,
          textRenderer: props => `In ${props.delivery_duration} Mins ${props.delivery_distance} km`,
          renderer: props => `<b>In ${props.delivery_duration} Mins</b> <br />${props.delivery_distance} km`,
        },
        {
          label: 'Total ETA and Distance',
          field: 'total_duration_text',
          useHtml: true,
          useResolver: true,
          textRenderer: props => `In ${props.total_duration} Mins ${props.total_distance} km`,
          renderer: props => `<b>In ${props.total_duration} Mins</b> <br />${props.total_distance} km`,
        },
        {
          label: 'Availability',
          field: 'activation_status_text',
          useHtml: true,
          useResolver: true,
          // eslint-disable-next-line no-nested-ternary
          textRenderer: props => `${props.activation_status && !props.incomplete_service_request_count ? 'Available' : props.incomplete_service_request_count ? `Busy (${props.incomplete_service_request_count})` : 'Not Available'}`,
          renderer: props => `<span class="${props.activation_status && !props.incomplete_service_request_count ? 'text-success' : 'text-danger'}">${
            // eslint-disable-next-line no-nested-ternary
              props.activation_status && !props.incomplete_service_request_count ? 'Available' : props.incomplete_service_request_count ? `Busy (${props.incomplete_service_request_count})` : 'Not Available'
            }</span>`,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          isButtons: true,
          buttons: [
            {
              text: 'Show on Map',
              variant: 'success',
              action: props => {
                this.$emit('show-on-map', props)
              },
            },
            {
              renderVariant: props => (this.orderDetails && this.orderDetails.agent_id === props.id ? 'success' : 'primary'),
              renderButtonText: props => (this.orderDetails && this.orderDetails.agent_id === props.id ? 'Assigned' : 'Assign'),
              action: props => {
                this.assignOrder(props)
              },
            },
          ],
        },
      ]
    },
  },
  watch: {
    orderId: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.getOrderDetailsById()
          if (this.$refs.findAgents) this.$refs.findAgents.loadItems()
        }
      },
    },
  },
  mounted() {
    if (this.$route.params.orderId) {
      this.orderId = this.$route.params.orderId
    }
  },
  methods: {
    setAgentInfo() {
      if (this.orderDetails) {
        const isAgentExist = this.allAgents.find(x => x.id === this.orderDetails.agent_id)
        if (isAgentExist) {
          this.assignedAgent = isAgentExist
        }
      }
    },
    changeParams({ responseData }) {
      showLoader()
      this.allAgents = responseData
      this.setAgentInfo()
      setTimeout(() => {
        this.$refs.findAgents.rows = this.$refs.findAgents.rows.filter(x => x.is_online === 1)
        hideLoader()
      }, 150)
    },
    getOrderDetailsById() {
      const { providerRequestDetail } = useProviderUi()

      providerRequestDetail(this.orderId)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.orderDetails = data.responseData.service
            this.setAgentInfo()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getApiEndpoint() {
      return `/provider/agents/find/agent?order_id=${this.orderId}`
    },
    assignOrder(payload) {
      const { providerAssignAgentToOrder } = useProviderUi()
      const formData = new FormData()

      formData.append('id', this.orderId)
      formData.append('admin_service', 'SERVICE')
      formData.append('agent_id', payload.id)
      formData.append('comments', '')

      showLoader()
      providerAssignAgentToOrder(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$emit('create-order', data.responseData)
            eventBus.$emit('refresh-on-going-services-socket', {
              userId: this.orderDetails.user_id,
              serviceId: this.orderId,
              service: 'SERVICE',
            })
            eventBus.$emit('provider-sync-app', {
              agentId: payload.id,
              showIncomingRequest: true,
            })
            eventBus.$emit('refresh-provider-accept-grid')
            eventBus.$emit('refresh-provider-accept-bars')
            this.getOrderDetailsById()
            if (this.$refs.findAgents) {
              this.$refs.findAgents.loadItems()
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
.provider-find-agents tr td.vgt-left-align:last-child {
  width: 300px;
}

.provider-find-agents .vue-good-datatable-view .card-header {
  padding: 0;
}

.provider-find-agents tr td.vgt-left-align:last-child span button:first-child {
  margin-bottom: 7px;
}
</style>
